

export const environment = {
    production: false,
    apiUrl: 'https://eternallyme.qa.backend.urmbrme.com/api/v1/',
    socialUrl: 'https://eternallyme.qa.backend.urmbrme.com/',
    stripePublicClient: 'pk_test_51MmGaACaUwKd9UK4Fml0NCBN1SuKW4UJ9uA0HDNkKVBXFQEr5L034FuTtrZ6xxN1xF1pDGd59zekL5PTAnJijLf200lXbwuwd5',
    tinyMceKey: '4yv58x4c8fxjhdj3zffi8ulkv7c7z8tw1jhfh2o2hg45dc41',
    appIvKey: '1234567891011121',
    encrypted: 'encryption-app-key'
};